export class ServerError extends Error {
	constructor(message: string) {
		super(message)
	}
}

export class ClientError extends Error {
	constructor(message: string) {
		super(message)
	}
}

export class BadRequestClientError extends ClientError {
	constructor(message = "Bad request.") {
		super(message)
	}
}

export class UnauthorizedClientError extends ClientError {
	constructor(message = "Unauthorized.") {
		super(message)
	}
}

export class NotFoundClientError extends ClientError {
	constructor(message = "Not found.") {
		super(message)
	}
}

export class ConflictClientError extends ClientError {
	constructor(message = "Conflict.") {
		super(message)
	}
}

export class TooManyRequestsClientError extends ClientError {
	constructor(message = "Too many requests.") {
		super(message)
	}
}

export type Server = {
	name: string
}

export type LogEvent = {
	timestamp: number,
	message: string
}

export type ServerStatus = "deprovisioned" | "provisioning" | "available" | "restarting" | "deprovisioning"

export type ServerInfo = {
	status: ServerStatus,
	players: Array<string>,
	maxPlayers: number,
	ipAddress?: string
}