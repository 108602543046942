import React, { useState, useEffect } from "react"
import { DateTime } from "../classes/DateTime"
import { none, Option, some } from "../classes/Option"
import useHttpQuery from "../hooks/useHttpQuery"
import useInterval from "../hooks/useInterval"
import { LogEvent, ServerInfo, ServerStatus } from "../Types"
import { getShade, match, mod } from "../Utilities"
import Details from "./Details"
import LoadingSpinner from "./LoadingSpinner"
import TimeInState from "./TimeInState"
import Toast from "./Toast"

export default function MainPage() {
	const [selectedTableTab, setSelectedTableTab] = useState<string>("Event Logs")
	const [isoTimestamps, setIsoTimestamps] = useState(false)
	const [error, setError] = useState<Option<Error>>(none)
	const [serverInfo, fetchServerInfo] = useHttpQuery<ServerInfo>("/get-server-info", "GET", undefined, none, e => setError(some(e)))
	const [logEvents, fetchEventLogs] = useHttpQuery<Array<LogEvent>>("/recent-events", "GET", undefined, some("logEvents"), e => setError(some(e)))
	const [_, startProvisioning] = useHttpQuery("/start-provisioning", "GET", undefined, none, e => setError(some(e)))
	const [pressedProvisionAt, setPressedProvisionAt] = useState<DateTime>(new DateTime(0))

	useEffect(() => {
		fetchServerInfo()
		fetchEventLogs()
	}, [])
	useInterval(() => {
		fetchServerInfo()
		fetchEventLogs()
	}, 5000, [])

	function onClickTimestamp() {
		setIsoTimestamps(!isoTimestamps)
	}

	async function onClickRequestProvisioning() {
		setPressedProvisionAt(DateTime.now)
		await startProvisioning()
		fetchServerInfo()
		fetchEventLogs()
	}

	function getStatusElement(status: ServerStatus): JSX.Element {
		return match<ServerStatus, JSX.Element>(status, [
			["deprovisioned", <span style={{color: "grey"}}>Deprovisioned</span>],
			["provisioning", <span style={{color: "orange"}}>Provisioning</span>],
			["available", <span style={{color: "white"}}>Available</span>],
			["restarting", <span style={{color: "orange"}}>Restarting</span>],
			["deprovisioning", <span style={{color: "grey"}}>Deprovisioning</span>]
		])
	}

	return <div>
		<div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", gap: 10}}>
			<span style={{fontSize: "xx-large", padding: 10}}>Space Engineers Server</span>
			{
				error.match(
					error => <Toast message={error.message} onClose={some(() => setError(none))}/>,
					() => undefined
				)
			}
			<div>
				<div style={{flexBasis: "100%", display: "flex", justifyContent: "center", alignItems: "stretch", padding: 10, backgroundColor: getShade(1), borderBottomLeftRadius: 4, borderBottomRightRadius: 4}}>
					<Details numColumns={2} details={[
						{ name: "Status", content: serverInfo.match(
								info => getStatusElement(info.status),
								() => <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<div style={{height: 20, width: 20}}><LoadingSpinner/></div>
								</div>
							)
						},
						{
							name: "Players",
							content: serverInfo.match(
								info => <span>{info.players.length}/{info.maxPlayers}</span>,
								() => <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<div style={{height: 20, width: 20}}><LoadingSpinner/></div>
								</div>
							)
						},
						{
							name: "Time in status",
							content: logEvents.match(
								events => <TimeInState logEvents={events.filter(x => !(x.message.includes("connected") || x.message.includes("disconnected")))}/>,
								() => <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<div style={{height: 20, width: 20}}><LoadingSpinner/></div>
								</div>
							)
						},
						{
							name: "Direct Connect Info",
							content: serverInfo.match(
								info => info.ipAddress ? <span>{info.ipAddress}:27016</span> : <span>-</span>,
								() => <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<div style={{height: 20, width: 20}}><LoadingSpinner/></div>
								</div>
							)
						}
					]}/>
				</div>
			</div>
		</div>
		{
			serverInfo.isDefined && serverInfo.get.status === "deprovisioned" && pressedProvisionAt.isBefore(DateTime.now.minusSeconds(10)) ? (
				<div className="clickable" onClick={onClickRequestProvisioning} style={{backgroundColor: "orange", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, padding: 10}}>
					<b>REQUEST PROVISIONING</b>
				</div>
			) : (
				undefined
			)
		}
		<div style={{display: "flex", justifyContent: "flex-start", alignItems: "stretch", marginTop: 10}}>
			{
				["Event Logs", "Current Players"].map(tableTab => <div key={tableTab} className="clickable" onClick={() => setSelectedTableTab(tableTab)} style={{
					backgroundColor: getShade(tableTab === selectedTableTab ? 1 : 0), padding: 10, borderTopLeftRadius: 4,
					borderTopRightRadius: 4}}>{tableTab}
				</div>)
			}
		</div>
		{
			selectedTableTab === "Current Players" ? (
				<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", userSelect: "text"}}>
					{
						serverInfo.match(
							serverInfo => serverInfo.players.length > 0 ? (
								<div>{
									serverInfo.players.map((player, index) => {
										const borderTopRadius = index === 0 ? 4 : 0
										const bottomBorderRadius = index === serverInfo.players.length-1 ? 4 : 0
										return <div key={index} style={{flexBasis: "100%", display: "flex", alignItems: "center", backgroundColor: getShade(1 + 0.3*mod(index, 2)), padding: 10, borderBottomLeftRadius: bottomBorderRadius, borderBottomRightRadius: bottomBorderRadius, borderTopLeftRadius: borderTopRadius, borderTopRightRadius: borderTopRadius}}>
											{player}
										</div>
									})
								}</div>
							) : (
								<div style={{backgroundColor: getShade(1), borderRadius: 4, height: 50}}></div>
							),
							() => <div style={{display: "flex", padding: 20, justifyContent: "center", alignItems: "center", borderRadius: 4, backgroundColor: getShade(1)}}>
								<div style={{height: 100, width: 100}}><LoadingSpinner/></div>
							</div>
						)
					}
				</div>
			) : (
				<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", userSelect: "text"}}>
					{
						logEvents.match(
							logEvents => <div>{
								logEvents.map((logEvent, index) => {
									const borderTopRadius = index === 0 ? 4 : 0
									const bottomBorderRadius = index === logEvents.length-1 ? 4 : 0
									return <div key={index} style={{flexBasis: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: getShade(1 + 0.3*mod(index, 2)), padding: 10, borderBottomLeftRadius: bottomBorderRadius, borderBottomRightRadius: bottomBorderRadius, borderTopRightRadius: borderTopRadius}}>
										<span onClick={onClickTimestamp}>{isoTimestamps ? new DateTime(logEvent.timestamp).toISOString() : new DateTime(logEvent.timestamp).toLocalTimeString()}</span>
										<span>{logEvent.message}</span>
									</div>
								})
							}</div>,
							() => <div style={{display: "flex", padding: 20, justifyContent: "center", alignItems: "center", borderRadius: 4, backgroundColor: getShade(1)}}>
								<div style={{height: 100, width: 100}}><LoadingSpinner/></div>
							</div>
						)
					}
				</div>
			)
		}
	</div>
}