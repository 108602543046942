import { PropsWithChildren } from "react"
import { none, Option } from "../classes/Option"
import Constants from "../Constants"

interface Props {
	color: Option<string>,
	message: string,
	onClose: Option<() => void>
}

function Toast(props: PropsWithChildren<Props>) {
	const color = props.color.getOrElse(Constants.RED_SHADE_T0)

	return <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", textAlign: "center", fontSize: "large", backgroundColor: color, borderRadius: "2pt", marginTop: "10pt", marginBottom: "10pt"}}>
		<span className="material-symbols-outlined" style={{color: color, padding: "10pt"}}>cancel</span>
		<div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 5}}>
			<span>{props.message}</span>
			{props.children}
		</div>
		<span className={`material-symbols-outlined${props.onClose.isDefined ? " clickable" : ""}`}
			style={{color: props.onClose.isDefined ? undefined : color, padding: "10pt"}}
			onClick={() => props.onClose.isDefined ? (props.onClose.get)() : null}>cancel</span>
	</div>
}

Toast.defaultProps = {
	color: none,
	onClose: none
}

export default Toast