import { useState } from "react"
import { none, Option, some } from "../classes/Option"
import Constants from "../Constants"
import { http } from "../Utilities"

export default function useHttpQuery<T>(path: string, method: "GET" | "POST", body: any, resParam: Option<string>, setError: (error: Error) => void): [Option<T>, () => Promise<void>] {
	const [item, setItem] = useState<Option<T>>(none)

	async function fetch() {
		try {
			const res = await http({
				resType: "json",
				url: `${Constants.API_ENDPOINT}${path}`,
				options: {
					method: method,
					body: JSON.stringify(body)
				}
			})
			setItem(some(resParam.match(param => res[param], () => res)))
		} catch (error) {
			setError(error as Error)
		}
	}

	return [item, fetch]
}