import { useState } from "react"
import { DateTime } from "../classes/DateTime"

interface Detail {
	name: string,
	content: any,
}

interface Props {
	details: Array<Detail>,
	numColumns: number
}

export default function Details(props: Props) {
	const [isoTimestamps, setIsoTimestamps] = useState<boolean>(false)

	return <div style={{flexBasis: "100%", display: "flex", justifyContent: "space-around", alignItems: "stretch", textAlign: "left"}}>
	{
		Array(props.numColumns).fill(0).map((_, column) => <div key={column} style={{flexBasis: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "stretch", padding: 20}}>
		{
			props.details
				.filter((_, index) => index % props.numColumns === column)
				.map(detail => {
					return <div key={detail.name} style={{padding: 10}}>
						<div><b>{detail.name}</b></div>
						<div style={{userSelect: "text"}} onClick={
							detail.content instanceof DateTime ? () => setIsoTimestamps(!isoTimestamps) : undefined
						}>{detail.content instanceof DateTime ? (
							isoTimestamps ? detail.content.toISOString() : detail.content.toLocalTimeString()
						) : (
							detail.content
						)}</div>
					</div>
				})
		}
		</div>)
	}
	</div>
}