import { LogEvent } from "../Types"
import { getTimeElapsedString } from "../Utilities"
import { useState } from "react"
import useInterval from "../hooks/useInterval"
import { Option, none, some } from "../classes/Option"

interface Props {
	logEvents: Array<LogEvent>
}

export default function TimeInState(props: Props) {
	const [secondsInState, setSecondsInState] = useState<Option<number>>(getSecondsInState())

	useInterval(() => {
		setSecondsInState(getSecondsInState)
	}, 1000, [props.logEvents])

	function getSecondsInState() {
		if (props.logEvents.length > 0) {
			return some(Math.floor((Date.now() - props.logEvents[0].timestamp) / 1000))
		} else {
			return none
		}
	}

	return <span>{secondsInState.map(getTimeElapsedString).getOrElse("-")}</span>
}