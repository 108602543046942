import React, { useEffect, useRef } from "react"

interface Props {
	lineWidth: number
}

const defaultProps = {
	color: "white",
	lineWidth: 2
}

function LoadingSpinner(props: Props) {
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const frameRequestRef = useRef<number>()
	const startTimeRef = useRef<number>(new Date().getTime() / 1000)

	function refresh() {
		const t = (new Date().getTime()/1000 - startTimeRef.current)

		if (canvasRef.current) {
			const canvasWidth = canvasRef.current.width
			const canvasHeight = canvasRef.current.height
			
			const canvasContext = canvasRef.current.getContext("2d")!
			canvasContext.clearRect(0, 0, canvasWidth, canvasHeight)
			canvasContext.lineWidth = props.lineWidth
			canvasContext.strokeStyle = getComputedStyle(canvasRef.current).color
	
			const startAngle = 2 * Math.PI * ((t*1.5) % 1)
			const endAngle = 2 * Math.PI * ((t*1.5 + 0.5) % 1)
	
			canvasContext.beginPath()
			canvasContext.arc(canvasWidth/2, canvasHeight/2, (Math.min(canvasWidth, canvasHeight) - props.lineWidth)/2, startAngle, endAngle)
			canvasContext.stroke()
		}

		frameRequestRef.current = window.requestAnimationFrame(refresh)
	}

	useEffect(() => {
		canvasRef.current!.width = canvasRef.current!.clientWidth
		canvasRef.current!.height = canvasRef.current!.clientHeight

		frameRequestRef.current = window.requestAnimationFrame(refresh)
		return (() => window.cancelAnimationFrame(frameRequestRef.current!))
	}, [])

	return <canvas ref={canvasRef} style={{width: "100%", height: "100%"}}/>
}

LoadingSpinner.defaultProps = defaultProps

export default LoadingSpinner
