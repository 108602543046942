import Constants from "../Constants"
import { getShade } from "../Utilities"
import MainPage from "./MainPage"

function App() {
	return (
		<div className="App" style={{backgroundColor: getShade(0), color: Constants.TEXT_COLOR}}>
			<div style={{margin: "0 auto", maxWidth: Constants.MENU_WIDTH, userSelect: "none", textAlign: "center"}}>
				<MainPage/>
			</div>
		</div>
	)
}

export default App
