import { none, some } from "./classes/Option"
import Constants from "./Constants"
import { BadRequestClientError, ClientError, ConflictClientError, NotFoundClientError, ServerError, TooManyRequestsClientError, UnauthorizedClientError } from "./Types"

export function getShade(darkness: number) {
	return interpolateHexColors(Constants.BACKGROUND_SHADE_T0, Constants.BACKGROUND_SHADE_T1, darkness)
}

export function interpolateHexColors(color0Hex: string, color1Hex: string, t: number) {
	[color0Hex, color1Hex].forEach(colorHex => {
		if (colorHex === null || colorHex.length !== 7 || colorHex.charAt(0) !== "#") {
			throw new Error("colorHex must start with '#' and be 7 characters long.")
		}
	})
	const interpolatedValues: Array<number> = []
	for (let i = 0; i < 3; i++) {
		const color0Value = parseInt(color0Hex.substring(2*i+1, 2*(i)+3), 16)
		const color1Value = parseInt(color1Hex.substring(2*i+1, 2*(i)+3), 16)
		const interpolated = ((1-t)*color0Value) + (t*color1Value)
		interpolatedValues.push(Math.max(0, Math.min(Math.round(interpolated), 255)))
	}
	return `#${interpolatedValues.map(x => x.toString(16).padStart(2, "0")).reduce((a, b) => a + b)}`
}

export function isMobile(): boolean {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function s(quantity: number) {
	return Math.abs(quantity) === 1 ? "" :  "s"
}

export function mod(x: number, y: number) {
	return ((x % y) + y) % y
}

export async function http(args: {resType: "json" | "blob" | "text", url: string, options?: RequestInit | undefined}) {
	return await withRetries(async () => {
		const res = await fetch(args.url, args.options)
		const parsedRes = await match<string, any>(args.resType, Object.entries({
			json: async () => await res.json(),
			blob: async () => await res.blob(),
			text: async () => await res.text()
		}))
		if (res.ok) {
			return parsedRes
		} else {
			const errorMessage = match<string, string>(args.resType, Object.entries({
				json: parsedRes.message,
				blob: res.status.toString(),
				text: parsedRes
			}))
			throw httpError(errorMessage, res.status)
		}
	}, error => error instanceof ServerError)
}

export async function withRetries<R>(work: () => Promise<R>, retryPredicate: (error: Error) => boolean, numRetries = 3) {
	for (let i = 0; i < numRetries; i++) {
		try {
			return await work()
		} catch (error) {
			if (!retryPredicate(error as Error) || i === numRetries-1) throw error
		}
	}
}

function httpError(message: string, statusCode: number): Error {
	if (statusCode === 400) return new BadRequestClientError(message)
	if (statusCode === 401) return new UnauthorizedClientError(message)
	if (statusCode === 404) return new NotFoundClientError(message)
	if (statusCode === 409) return new ConflictClientError(message)
	if (statusCode === 429) return new TooManyRequestsClientError(message)
	if (statusCode >= 400 && statusCode < 500) return new ClientError(message)
	if (statusCode >= 500 && statusCode < 600) return new ServerError(message)
	return new Error(message)
}

export function match<K, V>(key: K, matchCases: Array<[K, V | (() => V)]>): V {
	for (let i = 0; i < matchCases.length; i++) {
		if (matchCases[i][0] === key) {
			if (typeof matchCases[i][1] === "function") {
				return (matchCases[i][1] as (() => V))()
			} else {
				return matchCases[i][1] as V
			}
		}
	}
	throw new Error("Match error.")
}

export function getTimeElapsedString(totalSeconds: number): string {
	const days = Math.floor(totalSeconds / (24 * 3600))
	const hours = Math.floor((totalSeconds / 3600) % 24)
	const minutes = Math.floor((totalSeconds % 3600) / 60)
	const seconds = totalSeconds % 60

	const daysStr = days > 0 ? some(`${days} day${s(days)}`) : none
	const hoursStr = hours > 0 ? some(`${hours} hour${s(hours)}`) : none
	const minutesStr = minutes > 0 ? some(`${minutes} minute${s(minutes)}`) : none
	const secondsStr = daysStr.isDefined || hoursStr.isDefined || minutesStr.isDefined ? none : some(`${seconds} second${s(seconds)}`)

	return [daysStr, hoursStr, minutesStr, secondsStr].filter(x => x.isDefined).map(x => x.get).join(", ")
}