export interface Option<T> {
	isDefined: boolean
	isEmpty: boolean
	get: T
	getOrElse(defaultValue: T): T
	getOrUndefined: T | undefined
	match<R>(someFunction: (item: T) => R, noneFunction: () => R): R
	map<R>(mapFunction: (item: T) => R): Option<R>
}

export class Some<T> implements Option<T> {
	item: T
	constructor(item: T | NonNullable<T>) {
		this.item = item
	}
	get isDefined(): boolean {
		return true
	}
	get isEmpty(): boolean {
		return false
	}
	get get(): T {
		return this.item
	}
	getOrElse(_: T): T {
		return this.get
	}
	get getOrUndefined(): T | undefined {
		return this.item
	}
	match<R>(someFunction: (item: T) => R, _: () => R): R {
		return someFunction(this.item)
	}
	map<R>(mapFunction: (item: T) => R): Option<R> {
		return some(mapFunction(this.item))
	}
}

export class None<T> implements Option<T> {
	get isDefined(): boolean {
		return false
	}
	get isEmpty(): boolean {
		return true
	}
	get get(): T {
		throw new NoneGetError()
	}
	getOrElse(defaultValue: T): T {
		return defaultValue
	}
	get getOrUndefined(): T | undefined {
		return undefined
	}
	match<R>(_: (item: T) => R, noneFunction: () => R): R {
		return noneFunction()
	}
	map<R>(_: (item: T) => R): Option<R> {
		return none
	}
}

export function option<T>(item: T | undefined | null): Option<T> {
	if (item == undefined || item == null) {
		return none
	} else {
		return some(item)
	}
}

export function some<T>(item: T): Some<T> {
	return new Some(item)
}

export const none = new None<any>()

export class NoneGetError extends Error {
	constructor() {
		super("None.get")
	}
}